//排班管理
import request from '@/utils/request'

//排班列表
export function getSchedusList(params) {
    return request({
        url: '/admin/schedus/list',
        method: 'get',
        params
    })
}
//admin/schedus/add
//添加排班
export function postSchedus(data) {
    return request({
        url: '/admin/schedus/add',
        method: 'post',
        data
    })
}
//admin/schedus/update/{id}
//更新排班
export function putSchedus(data,id) {
    return request({
        url: `/admin/schedus/update/${id}`,
        method: 'put',
        data
    })
}
//admin/schedus/detail/{id}
//排班详情
export function getSchedusDetail(id) {
    return request({
        url: `/admin/schedus/detail/${id}`,
        method: 'get',
    })
}
//删除排班
export function deleteSchedus(id) {
    return request({
        url: `/admin/schedus/destroy/${id}`,
        method: 'delete',
    })
}
//排班日历表
export function getCalendar(params) {
    return request({
        url: `/admin/calendar/index`,
        method: 'get',
        params
    })
}
//admin/calendar/add
//添加日历排班
export function postCalendar(data) {
    return request({
        url: `admin/calendar/add`,
        method: 'post',
        data
    })
}
//修改排班
export function putCalendar(data) {
    return request({
        url: `/admin/calendar/update`,
        method: 'put',
        data
    })
}
//获取排班列表
export function getSchedus() {
    return request({
        url: `/admin/calendar/getSchedus`,
        method: 'get',
    })
}
//获取预约列表
export function getBookingList(params) {
    return request({
        url: `/admin/booking/index`,
        method: 'get',
        params
    })
}
//获取医生十五天排班
export function getMyBookingDate(params) {
    return request({
        url: `/admin/booking/myBookingDate`,
        method: 'get',
        params
    })
}
//获取医生排班时间
export function getMyBookingTime(params) {
    return request({
        url: `/admin/booking/getBookingtime`,
        method: 'get',
        params
    })
}
//修改排班时间
export function putMyBookingTime(data) {
    return request({
        url: `/admin/booking/update/${data.id}`,
        method: 'post',
        data
    })
}
//取消预约
export function deleteCancelBooking(id) {
    return request({
        url: `/admin/booking/cancel/${id}`,
        method: 'delete',
    })
}