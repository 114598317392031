<template>
  <div>
    <div style="margin-bottom:10px;margin-top:20px">
      <span>选择排班日期:</span>
      <el-select
        v-model="info.date"
        placeholder="请选择"
        size="mini"
        @change="changeDate"
      >
        <el-option
          v-for="item in dateTimeList"
          :key="item.date"
          :label="item.date"
          :value="item.date"
        >
        </el-option>
      </el-select>
    </div>
    <div>
      <span>选择排班时间:</span>
      <el-select
        v-model="info.time"
        placeholder="请选择"
        size="mini"
        :disabled="options.length == 0"
        @change="changeTime"
      >
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="item.time"
          :value="index"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { getMyBookingTime } from "@/api/rowWorks/index";
export default {
  name: "makeAppointmentTableHead",
  props: {
    dateTimeList: {
      type: Array,
      default: () => [],
    },
    id:{
        type: Number,
        default: () => 0,
    },
    doctor_id: {
      type: Number,
      default: () => 0,
    },
  },
  async created() {
    console.log(this.dateTimeList, "21443546");
  },
  data() {
    return {
      info: {}, //选择的日期
      options: [], //选择的时间
    };
  },
  methods: {
    async changeDate(e) {
      let schedu_id = 0;
      this.dateTimeList.forEach((item) => {
        if (item.date == e) {
          schedu_id = item.schedu_id;
          this.info = item;
        }
      });
      let data = {
        date: e,
        schedu_id: schedu_id,
        doctor_id: this.doctor_id,
      };
      console.log(data);
      const res = await getMyBookingTime(data);
      if (res.code == 200) {
        this.options = res.data;
      }
    },
    changeTime(e) {
      this.info = {...this.info,...this.options[e]}
      console.log(e,this.info, "changeTime");
      let data = {
        schedu_doctor_id: this.info.schedu_doctor_id,
        book_at: this.info.date,
        book_time: this.info.time,
        id:this.id
      }
       this.$emit("customEvent", data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
